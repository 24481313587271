<template>
	<div class="full-height">

		<div class="full-height">
			<div
				class="pa-10 text-right box"
			>
				<select
					v-model="item_search.year"
					class="pa-5 box mr-10"
				>
					<option
						v-for="year in year_list"
						:key="'year_' + year"
						:value="year"
					>{{ year }}년</option>
				</select>

				<select
					v-model="item_search.month"
					class="pa-5 box mr-10"
				>
					<option
						v-for="month in 12"
						:key="'month_' + month"
						:value="month"
					>{{ month }}월</option>
				</select>

				<select
					v-model="item_search.date"
					class="pa-5 box mr-10"
				>
					<option
						v-for="day in 31"
						:key="'day_' + day"
						:value="day"
					>{{ day }}일</option>
				</select>
			</div>

			<div class="mt-10 pa-10 bg-white ">
				<table class="table table-even">
					<thead>
					<tr>
						<th>결제일</th>
						<th>아이디</th>
						<th>신청 주문번호</th>
						<th>신청 결제번호</th>
						<th>주문번호</th>
						<th>결제번호</th>
						<th>처리상태</th>
						<th>관리</th>
					</tr>
					</thead>
					<tbody>
						<tr
							v-for="(item, index) in items"
							:key="'item_' + index"
						>
							<td>{{ item.year }}.{{ item.month }}.{{ item.date }}</td>
							<td>{{ item.member_id }}</td>
							<td>{{ item.regular_order_number }}</td>
							<td>{{ item.regular_transaction_id }}</td>
							<td>{{ item.new_order_number }}</td>
							<td>{{ item.new_transaction_id }}</td>
							<td>{{ codes.regular_status[item.status].name }}</td>
							<td></td>
						</tr>
					</tbody>
				</table>

				<Pagination
					:program="program"
					:options="item_search"
					:align="'center'"

					@click="getSearch"
					class="mt-auto"
				></Pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import Pagination from "../../components/Pagination";
	export default {
		name: 'RegularItems'
		, components: {Pagination}
		, props: ['Axios', 'codes']
		, data: function(){
			return {
				program: {
					name: '정기 결제 실행 내역'
					,top: true
					,title: true
					,bottom: false
				}
				, items: []
				, item_search: {
					page: 1
					, list_cnt: 10
					, total_count: 1
					, year: this.$route.params.year
					, month: this.$route.params.month
					, date: this.$route.params.date
				}
			}
		}
		, computed: {
			year_list: function(){
				let start = 2021
				let date = new Date()
				let year = date.getFullYear()
				let years = []
				for(let i = start; i <= year; i++){
					years.push(i)
				}

				return years
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)

					const result = await this.$request.init({
						method: 'get'
						,url: 'admin/getRegularItems'
						,data: this.item_search
					})

					if(result.success){
						this.items = result.data.result
						this.item_search.total_count = result.data.tCnt

					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message})
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getSearch: function(page){
				if(page){
					this.item_search.page = page
				}

				this.getData()
			}
		}
		, created() {
			this.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>